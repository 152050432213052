import React from "react";
import {Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, styled, TextField} from "@mui/material";
import {useTranslation} from "react-i18next";

const mapLingua = ['el', 'es','fr','it', 'pt']

export const LangSwitcher = () => {
    const {i18n, t} = useTranslation()
    return (
        <Box sx={{width:'100%'}}>
            <Stack direction={"row"} spacing={1} sx={{display: {xs: 'none', md:'block'}}}>
                {mapLingua.map(l =>
                    <Button key={l} size={"small"} sx={{fontWeight: 'bold', fontSize: '1.2rem', borderRadius:0}} color={"secondary"}
                            variant={i18n.language === l ? "contained" : "outlined"} onClick={() => i18n.changeLanguage(l)}>{l}</Button>
                )}
            </Stack>
            <Box sx={{display: {xs: 'block', md:'none'}, width:'10rem'}}>
                <FormControl fullWidth variant={"standard"}>
                    <InputLabel id="demo-simple-select-label">{t('login.language')}</InputLabel>
                    <Select
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={i18n.language}
                        label={t('login.language')}
                        onChange={(e) => i18n.changeLanguage(e.target.value)}
                    >
                        {mapLingua.map(l =>
                            <MenuItem key={l} value={l}>{l}</MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Box>
        </Box>
    )
}
