import React from "react";
import {Box, Stack} from "@mui/material";

export const LogoBox = () => {
    return(
        <Stack direction={"row"} justifyContent={"space-between"} sx={{width:'100%'}}>
            <Box sx={{width:'7rem'}}>
                <img style={{width:'100%'}} src={require('../assets/images/triumph-white.png')}/>
            </Box>
            <Box sx={{width:'7rem'}}>
                <img style={{width:'100%'}} src={require('../assets/images/sloggi-white.png')}/>
            </Box>
        </Stack>
    )
}
