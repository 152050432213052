import React from "react";
import {Box, Container, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LangSwitcher} from "../components/LangSwitcher";
import {LogoBox} from "../components/LogoBox";

export const Live = ({children}) => {
    const theme= useTheme()
    const sm = useMediaQuery(theme.breakpoints.up('md'))
    const {t} = useTranslation()

    return (
        <main className={'live'}>
            <Stack sx={{height:'100%'}} justifyContent={ sm ? 'space-between' : 'space-evenly'} spacing={sm ? 0 : 1} alignItems={"center"}>
                <Box sx={{width:'100%', position: {xs: 'relative'}, py: {md:2, xs:0}, top:0}}>
                    <Container maxWidth={sm ? 'xl' : 'xs'}>
                        <Stack direction={sm ? "row" : "column"} justifyContent={"space-between"}>
                            <Box>
                                <Typography fontWeight={'bold'} fontSize={"1.6rem"} color={'primary'}>{t('login.title')}</Typography>
                                <Typography fontWeight={'bold'} fontSize={"1.6rem"} color={"white"}>10-12 January</Typography>
                            </Box>
                        </Stack>
                    </Container>
                </Box>
                <Container>
                    {children}
                </Container>
                <Box sx={{width:'100%', position: {xs: 'relative'}, py: {md:2, xs:0}, bottom:0}}>
                    <Container maxWidth={sm ? 'xl' : 'xs'}>
                        <LogoBox/>
                    </Container>
                </Box>
            </Stack>
        </main>
    )
}
