import React from "react";
import {Home} from "./Home";
import {Default} from "../layout/Default";
import {Player} from "../components/Player";
import {Box, Typography} from "@mui/material";
import {Live} from "../layout/Live";

export const TestLive = ({test}) => {
    const vimeo = 'https://player.vimeo.com/video/788556584?h=ac395f064e'

    return(
        <Live>
            <Box sx={{width:'100%'}}>
                <Player/>
                {!test && <p style={{color: "white"}}>If you have any issues click <a href={vimeo} target={"_blank"}>here</a> for
                    a backup player (no translations) </p>}
            </Box>
        </Live>
    )
}
