import React from "react";

export const Player = () => {
  return(
      <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
        <iframe
            src={"https://players.plesh.co/triumph-sloggi-2023"}
            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
        ></iframe>
      </div>
  )
}
