import React from "react";
import {Box, Container, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LangSwitcher} from "../components/LangSwitcher";
import {LogoBox} from "../components/LogoBox";

export const Default = ({children}) => {
    const theme= useTheme()
    const sm = useMediaQuery(theme.breakpoints.up('md'))
    const {t} = useTranslation()

    return (
        <main>
            <Stack sx={{height:'100%'}} justifyContent={sm ? "space-between" : "start"} spacing={sm ? 0 : 1} alignItems={"center"}>
                <Container sx={{display:{xs:'block', md:'none'}, width:'min(40rem, 100%)', pt:2}}>
                    <LogoBox/>
                </Container>
                <Box sx={{width:'100%', position: {xs: 'relative', md:'absolute'}, py: {md:2, xs:0}}}>
                    <Container maxWidth={sm ? 'xl' : 'xs'}>
                        <Stack direction={sm ? "row" : "column"} justifyContent={"space-between"}>
                            <Box>
                                <Typography fontWeight={'bold'} fontSize={"1.6rem"} color={'primary'}>{t('login.title')}</Typography>
                                <Typography fontWeight={'bold'} fontSize={"1.6rem"} color={"white"}>{t('login.date')}</Typography>
                            </Box>
                            <Box>
                                <LangSwitcher/>
                            </Box>
                        </Stack>
                    </Container>

                </Box>
                <Box sx={{width: 'min(20rem, 100%)', pt: {md:0, xs:2}}}>
                    <img style={{width: '100%'}} src={require('../assets/images/logo.png')}/>
                </Box>
                {children}
                <Box sx={{width: '100%', display:{xs:'none', md:'block'}}}>
                    <img style={{width: '100%', marginBottom:'-0.15rem'}} src={require('../assets/images/bottom-logo.png')}/>
                </Box>
            </Stack>
        </main>
    )
}
