import logo from './logo.svg';
import './App.css';
import {Route, Routes} from "react-router-dom";
import {Home} from "./pages/Home";
import {TestLive} from "./pages/TestLive";

function App() {
  return (
    <div>
      <Routes>
        <Route path={'/'} element={<Home/>}/>
        <Route path={'/:language'} exact element={<Home/>}/>
        <Route path={'/live-test'} exact element={<TestLive test={false}/>}/>
      </Routes>
    </div>
  );
}

export default App;
