import {createTheme, responsiveFontSizes} from "@mui/material";
import '../index.css'

export const triumphTheme = responsiveFontSizes(createTheme({
    typography: {
        fontFamily:[
            'Gotham',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(',')
    },
    components:{
      MuiSelect:{
          styleOverrides:{
              outlined:{
                  border:'1px solid white',
                  color:"white"
              }
          }
      }
    },
    palette:{
        mode:'dark',
        primary:{
            main:"#E41F4C"
        },
        secondary:{
            main:"#fff",
            contrastText:'#68ABC1'
        }
    }
}))
