import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Container, Stack, styled, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {Default} from "../layout/Default";
import {Agenda} from "./Agenda";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useNavigate, useParams} from "react-router-dom";
import {Player} from "../components/Player";

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: '#E41F4C',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& label': {
            color: '#E41F4C'
        },
        '& fieldset': {
            color: '#E41F4C',
            borderColor: 'white',
            background: 'rgba(255,255,255,0.2)'
        },
        '&:hover fieldset': {
            borderColor: '#E41F4C',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#E41F4C',
        },
    },
});

const mapDati = [
    {
        id: 'name',
        type: 'text',
        label: "name",
    },
    {
        id: 'surname',
        type: 'text',
        label: "surname",
    },
    {
        id: 'email',
        type: 'email',
        label: "email",
    }
]

const mapLingua = ['el', 'es', 'fr', 'it', 'pt']

export const Home = () => {
    const {t, i18n} = useTranslation()
    const navigate = useNavigate()
    const {language} = useParams()
    const [dati, setDati] = useState({
        name: "",
        surname: "",
        email: ""
    })
    const [loading, setLoading] = useState(true)
    const [success, setSucces] = useState(true)

    useEffect(() => {
        setLoading(true)
        handleLanguage().then(() => setLoading(false))
    }, [])

    const handleLanguage = async () => {
        if (language && mapLingua.includes(language)) {
            await i18n.changeLanguage(language)
        } else {

        }
    }

    const compileDati = (id, value) => {
        setDati(prevState => ({...prevState, [id]: value}))
    }

    const sendDati = (e) => {
        e.preventDefault()
        console.log(dati)

    }

    return (
        <Box component={Default}>

            <Container maxWidth={success ? "md" : "xs"} sx={{py: 2}}>
                {success ?

                        loading ?
                            <Box sx={{width:'100%', textAlign:'center'}}>
                                <CircularProgress/>
                            </Box>
                            :
                            /*<Agenda/>*/
                            <Box sx={{textAlign:'center'}}>
                                <Typography sx={{color:'white'}} variant={"h4"} fontWeight={'bold'}>
                                    Thank you for participating
                                </Typography>
                            </Box>

                    :
                    <form onSubmit={sendDati}>
                        <Stack spacing={2} sx={{color: '#E41F4C'}}>
                            {mapDati.map(d =>
                                <CssTextField sx={{input: {color: '#000', fontWeight: 'bold'}}} type={d.type} key={d.id}
                                              label={t(`login.${d.label}`)}
                                              onChange={(e) => compileDati(d.id, e.target.value)}/>
                            )}
                            <Box sx={{textAlign: 'center'}}>
                                <Button size={"large"} endIcon={<ArrowForwardIosIcon/>} sx={{fontWeight: 'bold'}}
                                        variant={"contained"}
                                        type={"submit"}>{t('login.enter')}</Button>
                            </Box>
                        </Stack>
                    </form>}
            </Container>

        </Box>

    )
}
